import React from 'react'
import { SlArrowRight } from "react-icons/sl";
import Look from '../components/Look';
import sedan from "../assets/images/cars/swift.png";
import  primeSuv from "../assets/images/cars/ToyatoInnovaPrime.png";
import  xylo from "../assets/images/cars/xyloSuv.png"
import TopRoutesGrid from '../components/Home/TopRoutesGrid';
import  Toyota from "../assets/images/cars/ToyotaEtios.png";
import  ford from "../assets/images/cars/Fordaspire.jpg";
import suzuki from "../assets/images/cars/Suzukiertiza.jpg"
const Vehicle = () => {
  return (
    <div>
              <div className=" relative bg-zinc-800  flex justify-center items-center px-3">
    <div className=' py-16 md:py-28 lg:py-28' >
      <strong className="text-white text-7xl">Our Vehicle</strong>
      <span className='flex gap-x-2 items-center text-white cursor-pointer mt-10 text-xl text-center'>
        <a href="/">Home </a><SlArrowRight />
      Our Vehicle </span>

    </div>
    </div>
    <div className='container mx-auto mt-5 px-5'>

    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-5 p-4'>
                <TopRoutesGrid grid_image={sedan} heading="Swift Dzire" car="SEDAN" />
                <TopRoutesGrid grid_image={primeSuv} heading="Toyato Innova" car="PRIME SUV" width="w-[450px]"/>
                <TopRoutesGrid grid_image={xylo} heading="MAHINDRA XYLO" car="SUV" />
                <TopRoutesGrid grid_image={Toyota} heading="TOYOTA ETIOS" car="PRIME SEDAN" />
                <TopRoutesGrid grid_image={ford} heading="FORD ASPIRE" car="SEDAN"/>
                <TopRoutesGrid grid_image={suzuki} heading="SUZUKI ERTIGA" car="SUV" />

            </div>
   </div>
      <Look />
    </div>
  )
}

export default Vehicle
