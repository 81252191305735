import React from 'react'

const ServicePageComponent = ({img,heading,icon}) => {
  return (
    <div className='flex flex-col items-center gap-3'>
      <div>
        <img src={img}/>
      </div>
      <div className="relative ">
      <h3 className='text-lg leading-7 font-bold mt-2'>{heading}</h3>
      <div className="absolute h-[100px] w-[100px] top-[-100px] left-[28%] flex justify-center items-center rounded-full bg-red-600">{icon}
      </div>
      </div>

    </div>
  )
}

export default ServicePageComponent
