import React from 'react'
import { SlArrowRight } from "react-icons/sl";
import Form from "../components/Form";
import Look from '../components/Look';
const Booking = () => {
  return (
    <div>
        <div className=" relative bg-zinc-800  flex justify-center items-center px-3">
    <div className=' py-16 md:py-28 lg:py-28' >
      <strong className="text-white text-7xl">BOOK NOW</strong>
      <span className='flex gap-x-2 items-center text-white cursor-pointer mt-10 text-xl text-center'>
        <a href="/">Home </a><SlArrowRight />
      BOOK NOW </span>

    </div>
    </div>
    <div className="relative bg-fixed flex justify-center px-3">
        <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
          <Form/>
        </div>
      </div>

    <Look />
    </div>
  )
}

export default Booking
