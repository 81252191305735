import React from "react";

const CarsChargeTable = () => {
  return (
    <table className=" w-full">
      <tbody className=" text-left">
        <tr>
          <th className="p-[5px]">Vehicle Type</th>
          <th className="p-[5px]">One Way/Per KM</th>
          <th className="p-[5px]">Round Trip/ Per KM</th>
          <th className="p-[5px]">Additional Charge</th>
        </tr>
        <tr>
          <td className="p-[5px]">SEDAN</td>
          <td className="p-[5px]">Rs.14</td>
          <td className="p-[5px]">Rs.13</td>
          <td className="p-[5px]">Toll , Hill Station, Permit, Beta, Extra</td>
        </tr>
        <tr>
          <td className="p-[5px]">ETIOS</td>
          <td className="p-[5px]">Rs.15</td>
          <td className="p-[5px]">Rs.13</td>
          <td className="p-[5px]">Toll , Hill Station, Permit, Beta, Extra</td>
        </tr>
        <tr>
          <td className="p-[5px]">SUV</td>
          <td className="p-[5px]">Rs.19</td>
          <td className="p-[5px]">Rs.18</td>
          <td className="p-[5px]">Toll , Hill Station, Permit, Beta, Extra</td>
        </tr>
        <tr>
          <td className="p-[5px]">INNOVA</td>
          <td className="p-[5px]">Rs.20</td>
          <td className="p-[5px]">Rs.17</td>
          <td className="p-[5px]">Toll , Hill Station, Permit, Beta, Extra</td>
        </tr>
      </tbody>
    </table>
  );
};

export default CarsChargeTable;
