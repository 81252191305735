import React from 'react'
import { FaIndianRupeeSign } from "react-icons/fa6";

const TarrifComponent = ({img,heading,price}) => {
  return (
    <div className='flex flex-col items-center gap-3'>
      <div>
        <img src={img}/>
      </div>
      <div className="relative mt-8">
      <h3 className='text-lg leading-7 font-bold'>{heading}</h3>
      <div className="text-lg leading-7 font-medium mt-4 text-center">Start From : {price}</div>
      <div className="absolute h-[80px] w-[80px] top-[-100px] left-[30%] flex justify-center items-center rounded-full bg-red-600"><FaIndianRupeeSign size={25} className='text-white'/>
      </div>
      </div>
      <div className=' '>
                    <a href="#book_taxi_form" className='th-btn-reverse '>Book Now</a>
                </div>

    </div>
  )
}

export default TarrifComponent
