import React from 'react'
import Form from '../components/Form'
import { FaCheck } from 'react-icons/fa';
import { FaPhone } from "react-icons/fa6";
import airport from "../assets/images/airport transfer-covaicalltaxi.jpg";
import time from "../assets/images/24X7taxiservice.jpg";
import localrentals from "../assets/images/local rentals-covaicalltaxi.jpg";
import outstation from "../assets/images/outstation trips-covaicalltaxi.jpg"
import OurServiceComponent from "../components/Home/OurServiceComponent";
import vectorOneWayTaxi from "../assets/images/vectorCard1.jpg";
import airporttransfers from "../assets/images/vectorCard2.jpg";
import outstation3 from "../assets/images/VectorCard3.jpg";
import  hourlyrentals from "../assets/images/vectorCard4.jpg";
import Look from '../components/Look';
import Vision from '../components/Home/Vision';
import { SlArrowRight } from "react-icons/sl";
import ServicePageComponent from './ServicePageComponent';
import service1 from "../assets/images/service1.png";
import service2 from "../assets/images/service2.png";
import service3 from "../assets/images/Service3.png";
import service4 from "../assets/images/Service4.png";
import service5 from "../assets/images/Service5.png";
import service6 from "../assets/images/Service6.png";
import { TbBuildingCommunity } from "react-icons/tb";
import { MdEmojiTransportation } from "react-icons/md";
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { FaRegCalendarCheck } from "react-icons/fa";

import { PiTaxiFill } from "react-icons/pi";
import { FaBusinessTime } from "react-icons/fa";


const ServicePage = () => {
  return (
    <>
                           <div className=" relative bg-zinc-800  flex justify-center items-center px-3">
    <div className=' py-16 md:py-28 lg:py-28' >
      <strong className="text-white text-7xl">Our Way Taxi</strong>
      <span className='flex gap-x-2 items-center text-white cursor-pointer mt-10 text-xl text-center'>
        <a href="/">Home </a><SlArrowRight />
        Our Service </span>

    </div>
    </div>
    <div className='container mx-auto mt-5 px-5'>
<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
      <ServicePageComponent img={service1} heading="ROUND TRIP TAXI " icon={<TbBuildingCommunity size={25}/>}/>
      <ServicePageComponent img={service2} heading="INTERCITY TAXI " icon={<FaBusinessTime  size={25}/>}/>
      <ServicePageComponent img={service3} heading="AIRPORT TAXI " icon={<PiTaxiFill   size={25}/>}/>
      <ServicePageComponent img={service4} heading="OUTSTATION TAXI " icon={<FaRegCalendarCheck  size={25}/>}/>
      <ServicePageComponent img={service5} heading="DROP TAXI" icon={<FaPersonWalkingLuggage  size={25}/>} />
      <ServicePageComponent img={service6} heading="REGULAR TAXI " icon={<MdEmojiTransportation  size={25}/>}/>

</div>
</div>

        
         <Look />

  </>
  )
}

export default ServicePage
