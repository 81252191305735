import React from 'react'
import Form from '../components/Form'
import TableComponent from '../components/TableComponent';
import Table from "../components/Table"
import { SlArrowRight } from "react-icons/sl";
import Look from '../components/Look';
import TarrifComponent from './TarrifComponent';
import car1 from "../assets/images/cars/sedan.png";
import car2 from "../assets/images/cars/etios.png";
import car3 from "../assets/images/cars/suv.png";
import car4 from "../assets/images/cars/innova.png";

const TarrifPage = () => {
  return (
    <>
                      
                       <div className=" relative bg-zinc-800  flex justify-center items-center px-3">
    <div className=' py-16 md:py-28 lg:py-28' >
      <strong className="text-white text-7xl">Our Pricing</strong>
      <span className='flex gap-x-2 items-center text-white cursor-pointer mt-10 text-xl text-center'>
        <a href="/">Home </a><SlArrowRight />
        Our Pricing </span>

    </div>
    </div>
    <div className='container mx-auto mt-5 px-5'>
<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
     <TarrifComponent img={car1} heading="ONE WAY SEDAN" price="14"/>
     <TarrifComponent img={car2} heading="ONE WAY PRIME SEDAN" price="15"/>
     <TarrifComponent img={car3} heading="ONE WAY SUV" price="19"/>
     <TarrifComponent img={car4} heading="ONE WAY PRIME SUV" price="19"/>
     <TarrifComponent img={car1} heading="ROUND TRIP SEDAN" price="13"/>
     <TarrifComponent img={car2} heading="ROUND TRIP PRIME SEDAN" price="13"/>
     <TarrifComponent img={car3} heading="ROUND TRIP SUV" price="17"/>
     <TarrifComponent img={car4} heading="ROUND TRIP PRIME SUV" price="17"/>

</div>
</div>
   <Look />

    {/* <div className="form_bg relative bg-fixed flex justify-center px-3">
    <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
      <Form />
    </div>
  </div>
  <div className='container mx-auto mt-5 px-5 relative bg-fixed flex flex-col justify-between items-center px-3 gap-3'>
  <h1 className="text-xl md:text-3x lg:text-4xl  font-semibold mt-10">CAB TARIFF <span className='text-[#EDB701]'> DAY RENT BASIC BELOW </span> 300 KM(PER DAY)</h1>
  <div className='mt-5 md:mt-8 lg:mt-10  overflow-y-auto w-full flex justify-center'>
  <TableComponent headings={headings} rowheadings={rowheadings}/> </div>
</div>
<div className='container mx-auto mt-22 px-5 relative bg-fixed flex flex-col justify-between items-center px-3 gap-3'>
  <h1 className=" text-xl md:text-3xl lg:text-4xl  font-semibold mt-10">CAB TARIFF <span className='text-[#EDB701]'> KM BASIC ABOVE </span> 300 KM(PER DAY)</h1>
  <div className=' mt-5 md:mt-8 lg:mt-10  overflow-y-auto w-full flex justify-center'>
    <TableComponent headings={headings2} rowheadings={row2headings}/> </div>
</div>

<div className='container mx-auto mt-16 px-5 relative bg-fixed flex flex-col justify-between items-center px-3 gap-3'>
  <h1 className=" text-xl md:text-3xl lg:text-4xl  font-semibold mt-10">CAB TARIFF <span className='text-[#EDB701]'> KM BASIC ABOVE </span> 300 KM(PER DAY)</h1>
  <div className=' mt-5 md:mt-8 lg:mt-10  overflow-y-auto w-full flex justify-center'>
    <Table />
  </div>
</div>
<div className='container mx-auto mt-16 px-5 relative bg-fixed flex flex-col justify-between items-center px-3 gap-3'>
  <h1 className=" text-xl md:text-2xl lg:text-2xl  font-semibold mt-10">ADDITIONAL CHARGES <span className='text-[#EDB701]'> PER KM  AFTER </span> FREE HOURS</h1>
  <div className='mt-5 md:mt-8 lg:mt-10 overflow-y-auto w-full'>
    <TableComponent headings={headings3} rowheadings={row3headings} />
  </div>
</div> */}

</>
  )
}

export default TarrifPage
