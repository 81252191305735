import React from 'react'

const FooterRouteComponent = ({headings,Location1,Location2,Location3,Location4,Location5,Location6,Location7,Location8,Location9,Location10}) => {
  return (
    <div className='grid grid-cols-1'>
       <h2 className='text-2xl text-center text-[#e30d16] font-bold'>{headings}</h2>
       <div className='text-white text-center text-md mt-2 opacity-75'>{Location1}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location2}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location3}</div>
<div className='text-white text-center text-md mt-1 opacity-75'>{Location4}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location5}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location6}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location7}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location8}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location9}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location10}</div>

    </div>
  )
}

export default FooterRouteComponent
