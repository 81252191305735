import React from 'react'

const TopRoutesGrid = ({grid_image ,heading ,car,width}) => {
    return (
        <div className="grid grid-cols-1 bg-[#1b1b1b]">
        <div className='flex justify-center'> <img src={grid_image} className={width}/> </div>
         <div className='mx-8 bg-white rounded-tl-3xl rounded-tr-3xl'>
            <h3 className="text-xl font-bold uppercase leading-7 text-center ">{heading}</h3>
            <p className='text-md leading-6 text-red-600 text-center'>{car}</p>
         </div>

        </div>
    )
}

export default TopRoutesGrid