import React, { useEffect, useRef, useState } from "react";
import Form from '../components/Form'
import { FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { FaLocationDot } from "react-icons/fa6";
import emailjs from '@emailjs/browser';
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegCheckCircle } from 'react-icons/fa';
import { SlArrowRight } from "react-icons/sl";
import { CiUser } from "react-icons/ci";
import { PiPhoneCall } from "react-icons/pi";
import { CgMail } from "react-icons/cg";
import Look from "../components/Look";


const ContactPage = () => {
  const formRef = useRef(null);
  const[name,setName]=useState('');
  const[lastName,setLastName]=useState('');

  const[email,setEmail]=useState('');
  const[mobile,setMobile]=useState('');
  const[message,setMessage]=useState('');
  const[popup,setPopup]=useState(false);
  function popUpClose() {
    document.body.classList.remove("dis_scroll");
    setPopup(false);
  }

  const sendEmail = async(e) => {
    e.preventDefault();
      const serviceId="service_hhnwivk";
      const templateId="template_dkkx1dq";
      const publicKey="oVCDIcfzIYp0wfkFv";
      const data={
         service_id: serviceId,
         template_id: templateId,
         user_id: publicKey,
         template_params:{
          from_name:name,
          from_email:email,
          to_name:"Best One Way Cabs",
          mobile:mobile,
          message:message,
          reply_to:email,
         }
      }
      try{
        const res= await axios.post("https://api.emailjs.com/api/v1.0/email/send",data);
        console.log(res.data);
        setName('');
        setEmail('');
        setLastName('');
        setMobile('');
        setMessage('');
        setPopup(true);
      }
      catch(error){
        console.log(error)
      }
  };

  return (
    <>
    <div className=" relative bg-zinc-800  flex justify-center items-center px-3">
    <div className=' py-16 md:py-28 lg:py-28' >
      <strong className="text-white text-7xl">Contact Us</strong>
      <span className='flex gap-x-2 items-center text-white cursor-pointer mt-10 text-xl text-center'>
        <a href="/">Home </a><SlArrowRight />
      Contact</span>

    </div>
    
  </div>
  <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
  {/* <div className='grid grid-cols-1 items-center md:grid-cols-2 lg:grid-cols-3 mt-5 lg:mt-15'>
  <Link to="tel:+917200617575">
      <div className='flex flex-col gap-y-3 justify-between items-center rounded-lg shadow-lg px-3 py-10 border-2'>
        <div className=' flex bg-[#EDB701] h-24 w-24 rounded-full justify-center items-center '><FaPhone size={30} className="transform rotate-90 text-white" /></div>
        <p className='text-2xl font-semibold leading-8 opacity-85'>Phone Number</p>
        <p className='text-xl'>+91 72006 17575</p>
      </div>
      </Link>
      <Link to="mailto:contact@bestonewaycabs.com">
      <div className='flex flex-col gap-y-3 justify-between items-center rounded-lg shadow-lg px-3 py-10 border-2'>
        <div className=' flex bg-[#EDB701] h-24 w-24 rounded-full justify-center items-center '><IoMail size={30} className='text-white'/></div>
        <p className='text-2xl font-semibold leading-8 opacity-85'>Email Address</p>
        <p className='text-xl'>contact@bestonewaycabs.com</p>
      </div>
      </Link>

      <div className='flex flex-col gap-y-3 justify-between items-center rounded-lg shadow-lg px-3 py-8 border-2'>
        <div className=' flex bg-[#EDB701] h-24 w-24 rounded-full justify-center items-center '><FaLocationDot size={30} className='text-white'/></div>
        <p className='text-2xl font-semibold leading-8 opacity-85'>Location</p>
        <p className='text-md text-center'>C-9 7th Avenue Jayabharathcity,
        Avaniyapuram By-Pass Road, Avaniyapuram, Madurai, TN.</p>
      </div>
 
  </div> */}
  <div className="  mt-10 rounded-lg px-8 py-12 relative">
  <h4 className=' text-xl font-medium primary-color text-center leading-7'>Our Contact Form</h4>
  <h1 className="text-center font-semibold text-xl lg:text-5xl text-black mt-6">You Can Connect With Us</h1>

  <form ref={formRef} onSubmit={sendEmail}>
    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4">
            <div className="grid grid-col-1 p-3 relative">
            <input 
          type="text"
          placeholder='Enter Your Name'
          name="username"
          value={name}
          onChange={(e)=>setName(e.target.value)}
          className="  p-3 rounded-md mt-2 focus:outline-0 font-semibold hover:border-2 hover:border-red-600 " 
          required
          />
           <span className=" absolute text-red-600 top-[35%] right-[6%]"><CiUser size={25}/>
           </span>
            </div>
            <div className="grid grid-col-1 p-3 relative">
            <input 
          type="text"
          placeholder='Enter Your Last Name'
          name="username"
          value={lastName}
          onChange={(e)=>setLastName(e.target.value)}
          className="p-3 rounded-md mt-2 focus:outline-0 font-semibold hover:border-2 hover:border-red-600" 
          required
          />
           <span className=" absolute text-red-600 top-[35%] right-[6%]"><CiUser size={25}/></span>

            </div>

            <div className="relative grid grid-col-1 p-3">
            <input 
          type="email"
          placeholder='Enter Your Email '
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
          required
          className="p-3 rounded-md mt-2 focus:outline-0 font-semibold hover:border-2 hover:border-red-600" />
                        <span className=" absolute text-red-600 top-[35%] right-[6%]"><CgMail size={25}/></span>


            </div>
            <div className="grid grid-col-1 p-3 relative">
            <input
                type="int"
                className="p-3 rounded-md mt-2 focus:outline-0 resize:none font-semibold hover:border-2 hover:border-red-600"
                placeholder="Mobile number"
                name="mobile"
                value={mobile}
                onChange={(e)=>setMobile(e.target.value)}
                required
              />
             <span className=" absolute text-red-600 top-[35%] right-[6%]"><PiPhoneCall  size={25}/></span>

            </div>
            <div className="grid grid-col-1 p-3">
            <textarea 
          type="text"
          name="message"
          rows="8" 
          cols="50"
          className="p-3 rounded-md mt-2 focus:outline-0 resize-none font-semibold flex-col grow-3 hover:border-2 hover:border-red-600"
          value={message}
          placeholder="message"
          onChange={(e)=>setMessage(e.target.value)}
            required
          >

            </textarea>
            </div>
    </div>
    <div className="text-center mt-5">
          <button className="mt-4py-3 px-6 th-btn uppercase">Send Enquiry</button>
        </div>

    {/* <div className="grid grid-cols-1 mt-3">
       <div className="grid  grid-cols-1 p-3">   
           <label className="text-xl pl-2 font-medium">Name</label>
          <input 
          type="text"
          placeholder='Enter Your Name'
          name="username"
          value={name}
          onChange={(e)=>setName(e.target.value)}
          className="p-3 rounded-md mt-2 focus:outline-0 font-semibold" 
          />
    </div>
    <div className="grid  grid-cols-1 p-3">   
           <label className="text-xl pl-2 font-medium">Email Address</label>
          <input 
          type="email"
          placeholder='Enter Your Email '
          value={email}
          onChange={(e)=>setEmail(e.target.value)}

          className="p-3 rounded-md mt-2 focus:outline-0 font-semibold" />
    </div>
    <div className="grid  grid-cols-1 p-3">   
           <label className="text-lg pl-2 font-medium">Mobile Number</label>
           <input
                type="int"
                className="p-3 rounded-md mt-2 focus:outline-0 resize:none font-semibold"
                placeholder="Mobile number"
                name="mobile"
                value={mobile}
                onChange={(e)=>setMobile(e.target.value)}
      
              />
    </div>

    <div className="grid  grid-cols-1 p-3">   
           <label className="text-xl pl-2 font-semibold">Message</label>
          <textarea 
          type="text"
          name="message"
          rows="8" 
          cols="50"
          className="p-3 rounded-md mt-2 focus:outline-0 resize-none font-semibold flex-col grow-3"
          value={message}
          onChange={(e)=>setMessage(e.target.value)}

          >

            </textarea>
    </div>
    <div className="text-center mt-5">
          <button className="mt-4py-3 px-6 th-btn uppercase">Send Enquiry</button>
        </div>
    </div> */}
  </form>
  {popup ?
  <div className="fixed top-0 left-20%  h-full w-full z-[100]">
               <div className="w-[90%] md:w-[40%] min-h-[200px] bg-gray-100 top-1/2 left-1/2  md:top-[30%] lg:top-1/2 lg:left-[28%] -translate-x-1/2 -translate-y-1/2 absolute p-5">
            <span
              className="fixed top-2 right-2 cursor-pointer"
              onClick={popUpClose}
            >
              <AiOutlineCloseCircle className="text-[20px]" />
            </span>
            <div className="flex flex-col items-center mt-2">
            <FaRegCheckCircle className="text-[50px]" />
            
            <h2 className="text-center py-2 text-lg   ">Thank you for submitting. We will contact you shortly</h2>
            </div>
            </div>

  </div>
   :""}
  </div>
  </div>
  <Look />
  </>
  )
}

export default ContactPage
