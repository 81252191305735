import React from "react";
import banner from "../assets/images/backgroundTaxi.jpg";
import Form from "./Form";
import { FaPhone } from "react-icons/fa6";
import FormDuplicate from "./FormDuplicate";
import img1 from "../assets/images/ser1.png";
import img2 from "../assets/images/ser2.png";
import img3 from "../assets/images/ser3.png";
import img4 from "../assets/images/ser4.png";
import callicon from "../assets/images/callnow.gif"
import { Link } from 'react-router-dom';
import headimg from '../assets/images/headerbackimg.png';
import carCard from "../assets/images/carCard.png";


const BookingForm = () => {
  const divStyle = {
    backgroundImage: `url( ${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat"
    // animation :`( ${animate} ) 1s linear infinite`  ,  

  };

  return (
    <>
      <div className=" form_bg relative head_bg " style={divStyle} >

        <div className="mx-5  md:ml-[8%] sm:px-8 relative z-[3] grid grid-cols-1 lg:grid-cols-2 gap-5" >
          <div className="flex flex-col   justify-center gap-2 text-white text-xl">

            <div className="text-lg">Best One Way Cabs</div>
            <h3 className=" text-5xl font-bold mb-2">
              Superior Quality At Low Cost.
              {" "}
            </h3>




            {" "}
            Since day one, Best One Way Cabs has been on the road
            <br />to client satisfaction by providing stress-free taxi service.



            <div className=' flex justify-center lg:justify-start md:justify-start mt-12 '>
              <a href="#book_taxi_form" className='th-btn-reverse '>Book Now</a>
            </div>
          </div>
       
        <div className="bg-white  order-first lg:order-last md:order-first rounded-lg px-4  pb-4 " id="book_taxi_form">
          <Form />
          {/* <FormDuplicate /> */}
        </div>
        </div>
      </div>




    </>

  );
};

export default BookingForm;
