import React from 'react'
import FooterRouteComponent from './FooterRouteComponent'

const FooterRoute = () => {
    
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 py-12 px-5 gap-5">
      <FooterRouteComponent  headings="Chennai"  Location1="Chennai To Coimbatore Taxi" Location2="Chennai To Banglore Taxi" Location3="Chennai To Kochi Taxi" Location4="Chennai To Madurai Taxi" Location5="Chennai To Salem Taxi" Location6="Chennai To Trichy Taxi" Location7="Chennai To Pondicherry Taxi" Location8="Chennai To Tirunelveli Taxi" Location9="Chennai To Neyveli Taxi" Location10="Chennai To Rameshwaram Taxi"/>
      <FooterRouteComponent  headings="Coimbatore"  Location1=" Coimbatore To Chennai Taxi" Location2="Coimbatore To Banglore Taxi" Location3="Coimbatore To Kochi Taxi" Location4="Coimbatore To Madurai Taxi" Location5="Coimbatore To Salem Taxi" Location6="Coimbatore To Trichy Taxi" Location7="Coimbatore To Pondicherry Taxi" Location8="Coimbatore To Tirunelveli Taxi" Location9="Coimbatore To Neyveli Taxi" Location10="Coimbatore To Rameshwaram Taxi"/>
      <FooterRouteComponent  headings="Banglore"  Location1="Banglore To Chennai Taxi" Location2="Banglore To Coimbatore Taxi" Location3="Banglore To Kochi Taxi" Location4="Banglore To Madurai Taxi" Location5="Banglore To Salem Taxi" Location6="Banglore To Trichy Taxi" Location7="Banglore To Pondicherry Taxi" Location8="Banglore To Tirunelveli Taxi" Location9="Banglore To Neyveli Taxi" Location10="Banglore To Rameshwaram Taxi"/>
      <FooterRouteComponent  headings="Kochi"  Location1="Kochi To Chennai Taxi" Location2="Kochi To Coimbatore Taxi" Location3="Kochi To Banglore Taxi" Location4="Kochi To Madurai Taxi" Location5="Kochi To Salem Taxi" Location6="Kochi To Trichy Taxi" Location7="Kochi To Pondicherry Taxi" Location8="Kochi To Tirunelveli Taxi" Location9="Kochi To Neyveli Taxi" Location10="Kochi To Rameshwaram Taxi"/>
      <FooterRouteComponent  headings="Madurai"  Location1="Madurai To Chennai Taxi" Location2="Madurai To Coimbatore Taxi" Location3="Madurai To Banglore Taxi" Location4="Madurai To Thoothukudi Taxi" Location5="Madurai To Salem Taxi" Location6="Madurai To Trichy Taxi" Location7="Madurai To Pondicherry Taxi" Location8="Madurai To Tirunelveli Taxi" Location9="Madurai To Thiruvananthapuram Taxi" Location10="Madurai To Rameshwaram Taxi"/>
      <FooterRouteComponent  headings="Trichy"  Location1="Trichy To Chennai Taxi" Location2="Trichy To Banglore Taxi" Location3="Trichy To Thoothukudi Taxi" Location4="Trichy To Madurai Taxi" Location5="Trichy To Salem Taxi" Location6="Trichy To Coimbatore Taxi" Location7="Trichy To Pondicherry Taxi" Location8="Trichy To Tirunelveli Taxi" Location9="Trichy To Neyveli Taxi" Location10="Trichy To Rameshwaram Taxi"/>
      <FooterRouteComponent  headings="Salem"  Location1="Salem To Chennai Taxi" Location2="Salem To Banglore Taxi" Location3="Salem To Thoothukudi Taxi" Location4="Salem To Madurai Taxi" Location5="Salem To Coimbatore Taxi" Location6="Salem To Trichy Taxi" Location7="Salem To Pondicherry Taxi" Location8="Salem To Tirunelveli Taxi" Location9="Salem To Neyveli Taxi" Location10="Salem To Rameshwaram Taxi"/>
      <FooterRouteComponent  headings="Tirunelveli"  Location1="Tirunelveli To Chennai Taxi" Location2="Tirunelveli To Banglore Taxi" Location3="Tirunelveli To Thoothukudi Taxi" Location4="Tirunelveli To Madurai Taxi" Location5="Tirunelveli To Salem Taxi" Location6="Tirunelveli To Trichy Taxi" Location7="Tirunelveli To Pondicherry Taxi" Location8="Tirunelveli To Coimbatore Taxi" Location9="Tirunelveli To Thiruvanathapuram Taxi" Location10="Tirunelveli To Rameshwaram Taxi"/>
      <FooterRouteComponent  headings="KanyaKumari"  Location1="KanyaKumari To Chennai Taxi" Location2="KanyaKumari To Banglore Taxi" Location3="KanyaKumari To Thoothukudi Taxi" Location4="KanyaKumari To Madurai Taxi" Location5="KanyaKumari To Salem Taxi" Location6="KanyaKumari To Trichy Taxi" Location7="KanyaKumari To Pondicherry Taxi" Location8="KanyaKumari To Coimbatore Taxi" Location9="Chennai To Thiruvanathapuram Taxi" Location10="KanyaKumari To Rameshwaram Taxi"/>
      <FooterRouteComponent  headings="Thiruvanathapuram"  Location1="Thiruvanathapuram To Chennai Taxi" Location2="Thiruvanathapuram To Cochin Taxi" Location3="Thiruvanathapuram To Thoothukudi Taxi" Location4="Thiruvanathapuram To Madurai Taxi" Location5="Thiruvanathapuram To Thenkasi Taxi" Location6="Thiruvanathapuram To Trichy Taxi" Location7="Thiruvanathapuram To Tirunelveli Taxi" Location8="Thiruvanathapuram To Coimbatore Taxi" Location9="Thiruvanathapuram To KanyaKumari Taxi" Location10="Thiruvanathapuram To Rameshwaram Taxi"/>
      <FooterRouteComponent  headings="Rameshwaram"  Location1="Rameshwaram To Chennai Taxi" Location2="Rameshwaram To Banglore Taxi" Location3="Rameshwaram To Thoothukudi Taxi" Location4="Rameshwaram To Madurai Taxi" Location5="Rameshwaram To Salem Taxi" Location6="Rameshwaram To Trichy Taxi" Location7="Rameshwaram To Pondicherry Taxi" Location8="Rameshwaram To Coimbatore Taxi" Location9="Rameshwaram To Thiruvananthapuram Taxi" Location10="Rameshwaram To KanyaKumari Taxi"/>
      <FooterRouteComponent  headings="Thenkasi"  Location1="Thenkasi To Chennai Taxi" Location2="Thenkasi To Banglore Taxi" Location3="Thenkasi To Thoothukudi Taxi" Location4="Thenkasi To Madurai Taxi" Location5="Thenkasi To Salem Taxi" Location6="Thenkasi To Trichy Taxi" Location7="Thenkasi To Pondicherry Taxi" Location8="Thenkasi To Coimbatore Taxi" Location9="Thenkasi To Thiruvananthapuram Taxi" Location10="Thenkasi To Rameshwaram Taxi"/>

    </div>
  )
}

export default FooterRoute
