import React from 'react'
import { SlArrowRight } from "react-icons/sl";
import Vision from "../components/Home/Vision";
import Look from '../components/Look';

const Cities = () => {
  return (
    <div>
                   <div className=" relative bg-zinc-800  flex justify-center items-center px-3">
    <div className=' py-16 md:py-28 lg:py-28' >
      <strong className="text-white text-7xl">Our Vehicle</strong>
      <span className='flex gap-x-2 items-center text-white cursor-pointer mt-10 text-xl text-center'>
        <a href="/">Home </a><SlArrowRight />
      Our Vehicle </span>

    </div>
    </div>
    <Vision />
   <Look />
    </div>
  )
}

export default Cities
